import { Link } from "gatsby";
import {
    Box, Button, Image, Text, TextInput
} from "grommet";
import React, { useEffect, useState } from "react";
import Layout from "../components/elements/layout";
import StandardText from "../components/elements/standard_text";
import service from '../services/auth_service';
import { FONT_SIZE, getParamUrl } from "../services/utils";

const ResetPasswordPage = ({ logo }) => {

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [successful, setSuccessful] = useState(false);

    useEffect(() => {
        const _code = getParamUrl('code');
        setCode(_code);
        if (!_code) {
            setError('Reset token is missing.');
        }
    }, [])
    const changePassword = () => {
        if (!password || !passwordRepeat) {
            setError('All champs are required.');
            return;
        }
        if (password !== passwordRepeat) {
            setError('Password is not matched.');
            return;
        }

        setError('');
        setLoading(true);
        service.resetPassword({ password, passwordConfirmation: passwordRepeat, code })
            .then(_ => {
                setSuccessful(true);
            }).catch(err => {
                setLoading(false);
                const message = err.response.data?.error?.message;
                setError(message || 'Unexpected error');
                console.log(err.response);
            });
    }

    const inputStyle = { background: '#EDEDED', fontSize: 16, borderRadius: 10, border: 'none' };
    return (
        <Layout style={{ justifyContent: "center", alignContent: "center" }}
        >
            <Box
                justify="center"
                as="main"
                flex
                overflow="auto"
                width="large"
                direction="column"
                alignContent="center"
                alignSelf="center"
            >
                <Box width="medium" alignSelf="center" justify="center" alignContent="center" gap="medium">
                    <Image src={"https://meor.fr/lib_GoFZnqrgTVOIKMVV/jk7bgswsy6fhu9vt.png"} />

                    <Text size="small" style={{ color: '#dc3545' }} textAlign="center">{error}</Text>
                    {successful ?
                        <Box justify="center" align="center" gap="medium">
                            <StandardText
                                style={{ fontWeight: 'bold',   fontSize: FONT_SIZE.medium}}
                                textAlign='center'
                                label={"Password changed successully, you can now reconnect."}
                            />
                            <Box width="large" align="center" justify="center">
                                <Link to="/login">
                                    <Text style={{ color: '#707070',  fontSize: FONT_SIZE.medium }} textAlign="center">Connection</Text>
                                </Link>
                            </Box>
                        </Box> :
                        <Box width="large" gap="small">
                            <TextInput
                                placeholder="Password "
                                onChange={event => setPassword(event.target.value)}
                                style={inputStyle}
                                disabled={!code}
                                type='password'
                            />
                            <TextInput
                                placeholder="Password confirmation "
                                onChange={event => setPasswordRepeat(event.target.value)}
                                style={inputStyle}
                                type='password'
                                disabled={!code}
                            />
                            <Box width="small" alignSelf="center">
                                 <Button size="xxlarge"
                                    onClick={changePassword} disabled={loading}
                                    label="Modifier"
                                    style={{ background: 'brand' }}
                                />
                            </Box>
                            <Box width="medium" align="center" justify="center">
                                <Link to="/login">
                                    <Text style={{ color: '#707070', fontSize: FONT_SIZE.medium }} textAlign="center">Connection</Text>
                                </Link>
                            </Box>
                        </Box>
                    }
                </Box>

            </Box>
        </Layout>
    );
}

export default ResetPasswordPage;